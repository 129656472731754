import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import IconArrowLeftLight from 'static/images/icons/chevron-left-light.inline.svg';
import IconArrowRightLight from 'static/images/icons/chevron-right-light.inline.svg';

import './TestimonialsContainer.scss';

const TestimonialsCard = ({ item }) => (
  <div className="aaa-testimonials-item">
    <div className="aaa-testimonials-item__container">
      <blockquote>{item.description}</blockquote>
      <div className="aaa-testimonials-item__name">
        <SeparatorWithGradient className="aaa-testimonials-item__separator" />
        {item.author}
      </div>
    </div>
  </div>
);

const TestimonialsContainer = ({ data }) => {
  return (
    <div className="aaa-testimonials">
      <h2 className="aaa-testimonials__title">{data.title}</h2>
      <div className="aaa-testimonials__container">
        <Carousel
          className="aaa-testimonials__carousel"
          showThumbs={false}
          infiniteLoop
          showStatus={false}
          showIndicators
          swipeScrollTolerance={20}
          verticalSwipe="natural"
          selectedItem={Math.round(data.testimonials.length / 2) - 1}
          centerMode
          centerSlidePercentage={30}
          showArrows={false}
        >
          {data.testimonials.map((item, i) => (
            <TestimonialsCard key={i} item={item} />
          ))}
        </Carousel>
      </div>

      <div className="aaa-testimonials__container-mobile">
        <ContentBox>
          <Carousel
            className="aaa-testimonials__carousel"
            showThumbs={false}
            infiniteLoop={false}
            showStatus={false}
            showIndicators
            swipeScrollTolerance={100}
            verticalSwipe="natural"
            renderArrowNext={(clickHandler, hasNext) => (
              <button
                disabled={!hasNext}
                className="control-arrow control-next"
                aria-label="next slide/item"
                onClick={clickHandler}
              >
                <IconArrowRightLight className="light" />
              </button>
            )}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <button
                disabled={!hasPrev}
                className="control-arrow control-prev"
                aria-label="previous slide/item"
                onClick={clickHandler}
              >
                <IconArrowLeftLight className="light" />
              </button>
            )}
          >
            {data.testimonials.map((item, i) => (
              <TestimonialsCard key={i} item={item} />
            ))}
          </Carousel>
        </ContentBox>
      </div>
    </div>
  );
};

const TestimonialsContainerData = props => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        testimonialsYaml {
          title
          testimonials {
            author
            description
          }
        }
      }
    `}
    render={data => (
      <TestimonialsContainer data={data.testimonialsYaml} {...props} />
    )}
  />
);

export default TestimonialsContainerData;
